<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form id="iiotLdapSynchronizationGroupsForm" ref="synchronizationGroupsForm">
    <v-row class="pl-3">
      <v-col cols="12">
        <h3 class="mb-5">{{ $t('ldap.ldapSynchronization.groups') }}:</h3>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-text-field
              id="iiotLdapSynchronizationGroupsSearchBase"
              v-model="ldapData.groups.searchBase"
              :disabled="!isConnectionCorrect"
              :rules="[rules.required, rules.no_control_characters]"
              :label="$t('ldap.ldapSynchronization.searchBase')"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ ldapData.groups.searchBase }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-text-field
              id="iiotLdapSynchronizationGroupsFilter"
              v-model="ldapData.groups.filter"
              :disabled="!isConnectionCorrect"
              :rules="[rules.required, rules.no_control_characters]"
              :label="$t('ldap.ldapSynchronization.filter')"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ ldapData.groups.filter }}</span>
        </v-tooltip>
        <v-text-field
          id="iiotLdapSynchronizationGroupName"
          v-model="ldapData.groups.name"
          :disabled="!isConnectionCorrect"
          :rules="[rules.required, rules.no_control_characters]"
          :label="$t('ldap.ldapSynchronization.groupName')"
        />
        <v-text-field
          id="iiotLdapSynchronizationGroupAdminGroup"
          v-model="ldapData.groups.adminGroup"
          :disabled="!isConnectionCorrect"
          :rules="[rules.required, rules.no_control_characters]"
          :label="$t('ldap.ldapSynchronization.adminGroup')"
        />
        <div id="iiotLdapSynchronizationGroupSelectDefaultRole">
          <v-select
            v-model="ldapData.groups.default"
            :disabled="!isConnectionCorrect"
            :items="roles"
            :rules="[rules.required]"
            :label="$t('ldap.ldapSynchronization.defaultRole')"
            :menu-props="{ maxHeight: '180' }"
            item-text="name"
            attach
          />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants';
import Logger from '@/utils/logger';

export default {
  data: () => ({
    VALIDATION_REGEX,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('ldap.ldapSynchronization.required'),
        no_control_characters: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('ldap.noControlCharacter'),
      };
    },
    ldapData() {
      return this.$store.getters['ldap/getDefaultLdap'];
    },
    roles() {
      return this.$store.getters['roles/getRoles'];
    },
    isConnectionCorrect() {
      return this.$store.getters['ldap/getStatusOfTestConnection'];
    },
  },
  mounted() {
    try {
      this.$store.dispatch('roles/fetch_roles');
    } catch (e) {
      Logger.error(e);
    }
  },
};
</script>
