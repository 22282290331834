<!--
*  TTTech nerve-management-system
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <v-layout id="iiotLdapTitle" class="title" column>
      <h1>{{ $t('ldap.title') }}</h1>
      <v-divider />
    </v-layout>
    <v-row>
      <div id="iiotLdapAuthentication">
        <v-switch
          v-model="ldapData.active"
          :label="$t('ldap.ldapAuthentication')"
          :disabled="ldapActiveResponse || authState || !ldapData.fileName"
          class="mx-2"
          @click="changeLdapActivation()"
        />
      </div>
      <v-progress-circular v-if="ldapActiveResponse" class="mt-3" indeterminate color="primary" />
    </v-row>
    <v-form id="iiotLdapForm" ref="form" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="12">
          <ldap-connection ref="connection" />
          <ldap-synchronization ref="synchronization" class="mt-5" />
        </v-col>
      </v-row>
      <v-row class="pb-4 pl-3 ml-4">
        <nerve-button
          id="iiotLdapSave"
          :text="$t('baseForm.saveBtn')"
          :loading="saveResponse"
          :disabled="!isConnectionCorrect"
          type-of-btn="action"
          size="normal"
          type="submit"
          class="mt-5"
        />
      </v-row>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { NerveButton } from 'nerve-ui-components';
import LdapConnection from '@/components/ldap/ldap-connection/LdapConnection.vue';
import LdapSynchronization from '@/components/ldap/ldap-synchronization/LdapSynchronization.vue';
import Logger from '@/utils/logger';

export default {
  components: {
    LdapConnection,
    LdapSynchronization,
    NerveButton,
  },
  data: () => ({
    connection: 'connection',
    synchronization: 'synchronization',
    ldapAuthDialogTitle: '',
  }),
  computed: {
    ldapData() {
      return this.$store.getters['ldap/getDefaultLdap'];
    },
    isConnectionCorrect() {
      return this.$store.getters['ldap/getStatusOfTestConnection'];
    },
    saveResponse() {
      return this.$store.getters['ldap/saveResponse'];
    },
    ldapActiveResponse() {
      return this.$store.getters['ldap/ldapActiveResponse'];
    },
    authState() {
      return this.$store.getters['ldap/authState'];
    },
  },
  created() {
    try {
      this.$store.dispatch('ldap/get_default_ldap');
      this.$store.dispatch('ldap/set_test_connection_status', localStorage.getItem('connectionStatus'));
    } catch (e) {
      Logger.error(e);
    }
  },
  methods: {
    submitForm() {
      try {
        const model = this.ldapData;
        if (!this.isLdapFormValid()) {
          return;
        }

        // Filename is used as ID, so if it's not present
        // New configuration will be created
        if (model.fileName === '') {
          return this.$store.dispatch('ldap/create_ldap', model);
        }

        return this.$store.dispatch('ldap/update_ldap', model);
      } catch (e) {
        Logger.error(e);
      }
    },
    async changeLdapActivation() {
      this.ldapAuthDialogTitle = this.ldapData.active ? 'ldap.activeAuthDialog' : 'ldap.deactiveAuthDialog';
      this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
        title: 'ldap.ldapAuthenticationTitle',
        subTitle: this.ldapAuthDialogTitle,
        callback: async () => {
          try {
            this.$store.dispatch('utils/_api_request_handler/close_confirm');
            await this.$store.dispatch('ldap/change_ldap_activation', this.$store.getters['ldap/getDefaultLdap']);
          } catch (e) {
            Logger.error(e);
          }
        },
        cancelCallback: () => {
          this.ldapData.active = !this.ldapData.active;
        },
      });
    },
    isLdapFormValid() {
      return (
        this.$refs.form.validate() &&
        this.$refs.connection.$refs.connectionForm.validate() &&
        this.$refs.synchronization.$refs.synchronizationForm.validate() &&
        this.$refs.synchronization.$refs.groups.$refs.synchronizationGroupsForm.validate() &&
        this.$refs.synchronization.$refs.users.$refs.synchronizationUsersForm.validate() &&
        this.$refs.synchronization.$refs.relationship.$refs.userGroupRelationshipForm.validate()
      );
    },
  },
};
</script>
